import './assets/template/css/bootstrap.min.css';
import './assets/template/css/fonts.css';
import './assets/template/css/style.css';
import './assets/template/css/loader.css';
import React, { useEffect, useState, useMemo } from 'react';

import { BrowserRouter, Route, Routes, Navigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useAuthHeader, useSignOut } from 'react-auth-kit';
import Login from './pages/Login';
import ContactUs from './pages/userContactUs';
import { axiosRequestGet } from './network/axiosRequest';
import Dashboard from './pages/Dashboard';
import UserAvatar from './pages/Avatar/userAvatar';
import CreateUserAvatar from './pages/Avatar/createUserAvatar';
import EditUserAvatar from './pages/Avatar/editUserAvatar';
import TemplateList from './pages/Templates/templates';
import TemplateDetail from './pages/Templates/templateDetail';
import ProfileEdit from './pages/profile/editprofile';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

import logo from './assets/template/images/logo-icon.svg';
import logo2 from './assets/template/images/logo_txt.svg';
import avatar from './assets/template/img/avatar/avatar-illustrated-02.png';
import importScript from './global/importScript';
import ListHistory from './pages/History/listhistory';
import HistoryOutput from './pages/History/historyoutput';
import CreateTemplate from './pages/Templates/createtemplate';
import Sidebar from './components/sidebar';
import Loader from './components/loader'; 
import feather from 'feather-icons';
import Guide from './pages/guide';
import ProfileSettings from './pages/profile/profilecredit';
import Subscription from './pages/Subscription/listsubscription';
import TopupCredit from './pages/TopUp/topupcredit';
import ListPayment from './pages/Payment/listpayment';
import CategoryList from './pages/Templates/categorylist';
import ViewPayment from './pages/Payment/ViewPayment';
 

const App: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const authHeader = useAuthHeader();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState<{ username: string; points: number; profile_image: string } | null>(null);
  const [hasFetched, setHasFetched] = useState(false);
  const signOut = useSignOut();
  const [isLoading, setIsLoading] = useState(true)

  const authHeaderMemo = useMemo(() => authHeader(), [authHeader]);

  

  

   
    
    useEffect(() => {
      const fetchDetails = async () => {
        try {
          if (isAuthenticated() && !hasFetched) {
            const data = await axiosRequestGet('/users/details', { Authorization: authHeaderMemo });
            setUserDetails(data);
            setHasFetched(true);
          }
        } catch (error) {
          console.error('Failed to fetch user details:', error);
        } finally {
          setIsLoading(false); // Hide loader after fetching data
        }
      };
  
      fetchDetails();
    }, [authHeaderMemo, hasFetched]);

  useEffect(() => {
    feather.replace(); // Initialize Feather icons
  }, []);

  const handleLogout = () => {
    console.log("Logout button clicked");
    signOut();
    setTimeout(() => {
      window.location.href = '/';
    }, 500);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);


  return (
    <BrowserRouter>
      <div className="">
        {isAuthenticated() ? (
        <div className="admin-main-wrapper">

          <Sidebar/>
          
            <Routes>
                    <Route path="/" element={<Navigate to="/login" />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/profile-settings" element={<ProfileSettings />} />
                    <Route path="/profile-edit/:userId" element={<ProfileEdit />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/user-avatar" element={<UserAvatar />}/>
                    <Route path="/create-avatar" element={<CreateUserAvatar />}/>
                    <Route path="/edit-avatar/:user_avatar_id" element={<EditUserAvatar />} />
                    <Route path="/copy-hub" element={<TemplateList />} />
                    <Route path="/list-history" element={<ListHistory />}></Route>
                    <Route path="/history-output/:id" element={<HistoryOutput />} />
                    <Route path="/template-detail/:id" element={<TemplateDetail />} />
                    <Route path="/create-template" element={<CreateTemplate />} />
                    <Route path="/guide" element={<Guide />} />
                    <Route path="/subscription-plan" element={<Subscription />} />
                    <Route path="/topup-credit" element={<TopupCredit />} />
                    <Route path="/listpayment" element={<ListPayment />} />
                    <Route path="/copycategory-hub" element={<CategoryList />} />
                    <Route path="/copy-hub/:category_id" element={<TemplateList />} />
                    <Route path="/view-payments/:userId/:paymentId" element={<ViewPayment />} />
                    
                    
                    {/* Add more routes here */}
            </Routes>
      

    </div>
           





         
        ) : (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
            <Route path="/forgot-password" element={<ForgotPassword/>} />
            <Route path="/reset-password" element={<ResetPassword/>} />
          </Routes>
        )}
      </div>
    </BrowserRouter>
);
};


export default App;
