import React, { useEffect, useState } from 'react';
import { Button } from '@blueprintjs/core';
import { useForm } from 'react-hook-form';
import { useAuthUser } from 'react-auth-kit';
import { axiosRequestGet, axiosRequestPut, axiosRequestDelete } from '../../network/axiosRequest';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import icon from '../../assets/template/images/command.svg';
import Header from '../../components/header';


interface FormField {
  className: string;
  inputType: string;
  lenMsg: string;
  name: string;
  optional: boolean;
  options: { key: string; value: string }[];
  placeholder: string;
  requiredMsg: string;
  subtitle: string;
  tag: string;
  title: string;
  value: string;
}

interface GroupData {
  groupdata: FormField[];
  groupname: string;
  heading: string;
}

interface FieldData {
  field: GroupData[];
  id: number;
  name: string;
  status: number;
  subtitle: string;
}

interface UserAvatar {
  created_at_avatar: string;
  idealclient: string;
  id: number;
  avatar_name: string;
  user_id: number;
  form_field_id: number;
  status: number;
  user_response: any; // This could be an object or array
}

const EditUserAvatar: React.FC = () => {
  const { register, handleSubmit, setValue, reset , formState: { errors } } = useForm();
  const auth = useAuthUser();
  const token = auth()?.token;
  const navigate = useNavigate();
  const { user_avatar_id } = useParams<{ user_avatar_id: string }>();
  const [formFields, setFormFields] = useState<FieldData | null>(null);
  const [userAvatar, setUserAvatar] = useState<UserAvatar | null>(null);
  const [active, setActive] = useState(' show active');
  const [avatars, setAvatars] = useState<UserAvatar[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const authData = auth();
        if (!authData || !authData.token) {
          throw new Error('User is not authenticated.');
        }

        const headers = {
          Authorization: `Bearer ${authData.token}`,
          'Content-Type': 'application/json',
        };

        // Fetch form fields
        const formFieldsResponse = await axiosRequestGet('/user/form_field', headers);
        const avatarField = formFieldsResponse.find((field: FieldData) => field.id === 15);
        if (!avatarField) {
          throw new Error('Ideal Client creation form field not found.');
        }
        setFormFields(avatarField);
       
        // Fetch user avatars
        const avatarsResponse = await axiosRequestGet('/user/user_avatars', headers);
        setAvatars(avatarsResponse);

        // Fetch the specific user avatar based on user_avatar_id
        const userAvatarResponse = await axiosRequestGet(`/user/user_avatar/${user_avatar_id}`, headers);
        setUserAvatar(userAvatarResponse);
       setTimeout(() => setLoading(false), 1000);
        // Reset form values based on userAvatar
        reset({
          avatar_name: userAvatarResponse?.avatar_name || '',
          ...userAvatarResponse?.user_response
        });
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error('Failed to fetch data:', error.message);
          setError('Failed to load data.');
        } else {
          console.error('An unknown error occurred:', error);
          setError('An unknown error occurred.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token, user_avatar_id, reset]);

  useEffect(() => {
    if (userAvatar) {
      setValue('avatar_name', userAvatar.avatar_name);
      if (Array.isArray(userAvatar.user_response)) {
        userAvatar.user_response.forEach((responseItem: any) => {
          for (const [key, value] of Object.entries(responseItem)) {
            setValue(key, value);
          }
        });
      } else if (typeof userAvatar.user_response === 'object') {
        for (const [key, value] of Object.entries(userAvatar.user_response)) {
          setValue(key, value);
        }
      } else {
        console.error('Unexpected user_response type:', typeof userAvatar.user_response);
      }
    }
  }, [userAvatar, setValue]);

  const onSubmit = async (data: any) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to save these changes?',
        icon: 'warning',
        showCancelButton: true, 
        customClass: {
          confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
          cancelButton: 'btn btn-danger danger-filled-button py-2 px-4',
        },
        confirmButtonText: 'Yes, save changes!',
        cancelButtonText: 'Cancel',
      });

      if (result.isConfirmed) {
        const authData = auth();
        if (!authData || !authData.token) {
          throw new Error('User is not authenticated.');
        }

        const headers = {
          Authorization: `Bearer ${authData.token}`,
          'Content-Type': 'application/json',
        };

        const { avatar_name, ...restData } = data;
        const user_response = restData;

        const payload = {
          avatar_name,
          form_field_id: 15,
          user_response,
          status: data.status || 0,
        };

        const response = await axiosRequestPut(`/admin/user_avatar/${user_avatar_id}/update`, payload, headers);
        console.log('Ideal Client updated successfully!', response);
        Swal.fire({
          title: 'Saved!',
          text: 'Your changes have been saved.',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
          },
          confirmButtonText: 'Ok',
        });
        navigate('/user-avatar');
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Failed to update Ideal Client:', error.message);
        setError('Failed to update Ideal Client.');
      } else {
        console.error('An unknown error occurred:', error);
        setError('An unknown error occurred.');
      }
    }
  };

  const onDelete = async () => {
    try {
      const authData = auth();
      if (!authData || !authData.token) {
        throw new Error('User is not authenticated.');
      }

      const headers = {
        Authorization: `Bearer ${authData.token}`,
        'Content-Type': 'application/json',
      };

      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'This action cannot be undone!',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
          cancelButton: 'btn btn-danger danger-filled-button py-2 px-4',
        },
        confirmButtonText: 'Yes, delete it!',
      });

      if (result.isConfirmed) {
        await axiosRequestDelete(`/admin/user_avatar/${user_avatar_id}/delete`, headers);
        console.log('Ideal Client deleted successfully!');
        Swal.fire({
          title: 'Deleted!',
          text: 'Your Ideal Client has been deleted.',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
          },
          confirmButtonText: 'Ok',
        });
        navigate('/user-avatar');
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Failed to delete Ideal Client:', error.message);
        setError('Failed to delete Ideal Client.');
      } else {
        console.error('An unknown error occurred:', error);
        setError('An unknown error occurred.');
      }
    }
  };

  const handleAvatarSelect = (id: number) => {
    navigate(`/edit-avatar/${id}`);
  };


  const breadcrumbs = [
    { label: 'Dashboard', url: '/dashboard' },
    { label: 'Ideal Client', url: '/user-avatar' },
    { label: 'Edit Ideal Client' },
  ];

  return (
    <section className="main-right-wrapper">
    <Header pageTitle="Ideal Client" breadcrumbs={breadcrumbs} enable={true} />
    <div className="main-right-content">
        <div className="container-fluid">
            <div className="row wlcom_bx mb-3 d-md-none d-block">
                <div className="col-12">
                    <div className="page-title-box">
                        <h2 className="page-title">Ideal Client</h2>
                        <div className="breadcrumb">
                                {breadcrumbs.map((breadcrumb:any, index:any) => (
                                    <span key={index}>
                                        {breadcrumb.url ? (
                                        <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                                        ) : (
                                        <span>{breadcrumb.label}</span>
                                        )}
                                        {index < breadcrumbs.length - 1 && <span className="separator"> / </span>}
                                    </span>
                                    ))}
            </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-lg-5 col-xl-4">
                    <div className="p-3 bg-light rounded mb-4" style={{maxHeight: '100vh', overflowY: 'auto'}}>
                        <h5 className="mb-3 text-center">All Ideal Clients</h5>
                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            {avatars.map((avatar) => (
                                <button
                                    key={avatar.id}
                                    className={`nav-link avatar-card-tab-item ${avatar.id === userAvatar?.id ? 'active' : ''}`}
                                    id={`v-pills-avatar${avatar.id}-tab`}
                                    data-bs-toggle="pill"
                                    data-bs-target={`#v-pills-avatar${avatar.id}`}
                                    type="button"
                                    role="tab"
                                    aria-controls={`v-pills-avatar${avatar.id}`}
                                    aria-selected={avatar.id === userAvatar?.id}
                                    onClick={() => handleAvatarSelect(avatar.id)}
                                >
                                    <div className="icon-box-circle">
                                        {/* You can replace this with avatar-specific image if available */}
                                        <img src={icon} alt="image" title="" />
                                    </div>
                                    <h4 className="font15 mb-1">{avatar.avatar_name}</h4>
                                    <p className="mb-0 font-light-muted font14">{avatar.idealclient}</p>
                                    <p className="mb-0 font-light-muted font14">{avatar.created_at_avatar}</p>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-7 col-xl-8">
                    <div className="p-3 bg-light rounded mb-4">
                        <h5 className="mb-3 text-center">Edit Ideal Client</h5>
                        <div className="bg-white border rounded p-3">
                            <div className="tab-content" id="v-pills-tabContent">
                            {loading ? <div className="loading">Loading....</div> : 
                            <>
                             {userAvatar ?
                                    <div className={`tab-pane fade d-block show2 show active ${active}`} id={`v-pills-avatar${userAvatar.id}`} role="tabpanel" aria-labelledby={`v-pills-avatar${userAvatar.id}-tab`}>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="form-group mb-3">
                                                <label htmlFor="avatarName" className="font15 mb-0 font500">Ideal Client Name</label>
                                                <p className="mb-2 font13 font-muted font300">
                                                  {formFields?.subtitle}
                                                </p>
                                                <input
                                                    id="avatarName"
                                                    type="text"
                                                    {...register('avatar_name', { required: true })}
                                                    className="form-control mb-3"
                                                    placeholder={userAvatar.avatar_name}
                                                />
                                            </div>
                                            {loading ? <div className="loading">Loading....</div> : 
                                            <>
                                              {formFields ? formFields.field.map((group, groupIndex) => (
                                              <div key={groupIndex}>
                                                <h5>{group.heading}</h5>
                                                {group.groupdata.map((field, fieldIndex) => (
                                                  <div key={fieldIndex} className="form-group mb-3">
                                                    <label className="font15 mb-0 font500">{field.title}</label>
                                                    <p className="mb-2 font13 font-muted font300">
                                                      {field.subtitle}
                                                    </p>

                                                    {/* Handling different input types */}
                                                    {field.tag === 'input' ? (
                                                      <>
                                                        {field.inputType === 'checkbox' ? (
                                                          <div>
                                                            <input
                                                              type="checkbox"
                                                              id={field.name}
                                                              className="form-check-input"
                                                              {...register(field.name, { required: field.optional ? false : field.requiredMsg })}
                                                            />
                                                            <label htmlFor={field.name} className="form-check-label">
                                                              {field.title}
                                                            </label>
                                                          </div>
                                                        ) : field.inputType === 'radio' ? (
                                                          <div>
                                                            {field.options.map((option, idx) => (
                                                              <div key={idx} className="form-check">
                                                                <input
                                                                  type="radio"
                                                                  id={`${field.name}_${idx}`}
                                                                  value={option.value}
                                                                  className="form-check-input"
                                                                  {...register(field.name, { required: field.optional ? false : field.requiredMsg })}
                                                                />
                                                                <label htmlFor={`${field.name}_${idx}`} className="form-check-label">
                                                                  {option.key}
                                                                </label>
                                                              </div>
                                                            ))}
                                                          </div>
                                                        ) : (
                                                          <input
                                                            type={field.inputType} // This handles types like text, email, file, etc.
                                                            id={field.name}
                                                            {...register(field.name, { required: field.optional ? false : field.requiredMsg })}
                                                            className="form-control mb-3"
                                                            placeholder={field.placeholder}
                                                          />
                                                        )}

                                                        {errors[field.name] && (
                                                          <p style={{ color: 'red', fontSize: '12px' }}>
                                                            {errors[field.name]?.message?.toString() || `${field.title} is required`}
                                                          </p>
                                                        )}

                                                        {/* Hidden input for title */}
                                                        <input
                                                          type='hidden'
                                                          className="form-control"
                                                          placeholder=""
                                                          value={field.title}
                                                          {...register(field.name + '_title', { required: field.optional ? false : field.requiredMsg })}
                                                        />
                                                      </>
                                                    ) : field.tag === 'select' ? (
                                                      <>
                                                        <select
                                                          id={field.name}
                                                          className="form-control mb-3"
                                                          {...register(field.name, { required: field.optional ? false : field.requiredMsg })}
                                                        >
                                                          <option value="" disabled>Select an option</option>
                                                          {field.options.map((option, idx) => (
                                                            <option key={idx} value={option.value}>
                                                              {option.key}
                                                            </option>
                                                          ))}
                                                        </select>

                                                        {errors[field.name] && (
                                                          <p style={{ color: 'red', fontSize: '12px' }}>
                                                            {errors[field.name]?.message?.toString() || `${field.title} is required`}
                                                          </p>
                                                        )}

                                                        {/* Hidden input for title */}
                                                        <input
                                                          type='hidden'
                                                          className="form-control"
                                                          placeholder=""
                                                          value={field.title}
                                                          {...register(field.name + '_title', { required: field.optional ? false : field.requiredMsg })}
                                                        />
                                                      </>
                                                    ) : null}
                                                  </div>
                                                ))}
                                              </div>
                                            )) : <div className="loading">Loading....</div>}

                                            
                                            </>
                                            }
                                            
                                            <div className="mb-3 d-flex justify-content-between">
                                                {/* <Button type="button" onClick={onDelete} className="btn btn-danger danger-filled-button py-2 px-4">Delete Avatar</Button> */}
                                                <Button type="submit" className="btn btn-primary primary-filled-button py-2 px-4">Save Changes</Button>
                                            </div>
                                        </form>
                                        
                                    </div>
                                : <div className="loading">Loading....</div>}
                            </>     
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

  );
};

export default EditUserAvatar;
