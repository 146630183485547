import React, { useEffect, useState, useMemo } from "react";
import { axiosRequestGet } from '../network/axiosRequest';
import { useAuthUser } from 'react-auth-kit';
import '../assets/template/css/style.css';
import Header from '../components/header';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Link } from "react-feather";

interface VideoContent {
  id: number;
  avatar: string;
  template: string;
  dashboard: string;
  help: string;
  created_at_video: string;
  updated_at_video: string;
}

interface FAQ {
  id: number;
  title: string;
  description: string;
  status: number;
  created_at_avatarfaq: string;
  updated_at_avatarfaq: string;
  position: number;
}

interface FAQ2 {
  id: number;
  title: string;
  description: string;
  status: number;
  created_at_templatefaq: string;
  updated_at_templatefaq: string;
  position: number;
}

const Guide: React.FC = () => {
  const [videoContent, setVideoContent] = useState<VideoContent | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [faqList, setFaqList] = useState<FAQ[]>([]);
  const [faq2List, setFaq2List] = useState<FAQ2[]>([]);
  const [value, setValue] = useState('1');
  const auth = useAuthUser();

  const token = useMemo(() => auth()?.token, [auth]);

  useEffect(() => {
    fetchData();
  }, [token]);

  const fetchData = async () => {
    try {
      if (!token) {
        throw new Error('User is not authenticated.');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

      const videoContentResponse = await axiosRequestGet('/admin/video_content', headers);
      setVideoContent(videoContentResponse[0] as VideoContent);
      const faqResponse = await axiosRequestGet('/api/users/faq/avatarfaq', headers);
      setFaqList(faqResponse.avatarfaq as FAQ[]);
      const faq2Response = await axiosRequestGet('/api/users/faq/templatefaq', headers);
      setFaq2List(faq2Response.templatefaq as FAQ2[]);

    } catch (error) {
      console.error('Failed to fetch data:', error);
      setError('Failed to load data.');
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const breadcrumbs = [
    { label: 'Dashboard', url: '/dashboard' },
    { label: 'Guide' },
  ];


  return (
    <section className="main-right-wrapper">
    <Header pageTitle="Guide" breadcrumbs={breadcrumbs} enable={true} />
    <div className="main-right-content">

    <div className="container-fluid">
    <div className="row wlcom_bx mb-3 d-md-none d-block">
                <div className="col-12">
                    <div className="page-title-box">
                        <h2 className="page-title">Guide</h2>
                        <div className="breadcrumb">
                            {breadcrumbs.map((breadcrumb: any, index: any) => (
                                <span key={index}>
                                    {breadcrumb.url ? (
                                        <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                                    ) : (
                                        <span>{breadcrumb.label}</span>
                                    )}
                                    {index < breadcrumbs.length - 1 && <span className="separator"> / </span>}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
 
          
                    <div className="bs-callout bs-callout-danger">
                      <h6>IMPORTANT: Watch this Walkthrough First To Create High-Converting Copy In Seconds</h6>
                      <div className="col-lg-6">
                        <div className="howtousevideo">
                          <iframe
                            style={{ margin: "20px 0" }}
                            width="100%"
                            height="315"
                            src={videoContent?.dashboard || ''}
                            title="Dashboard Video Content"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                    </div>

                  
                  
                    <div className="bs-callout bs-callout-danger">
                      <h6>Ideal Client Walkthrough</h6>
                      <div className="row">

                      <div className="col-lg-6">
                        <div className="howtousevideo">
                          <iframe
                            style={{ margin: "20px 0" }}
                            width="100%"
                            height="315"
                            src={videoContent?.avatar || ''}
                            title="Ideal Client Video Content"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                      <div className="col-lg-6">
                              <div className="d-flex align-items-center mb-2">
                                <div className="font18 font600">FAQ</div>
                              </div>
                              <div className="accordion accordion-flush discover-accordion-section mb-4" id="accordionFlushExample">
                                {faqList.length > 0 ? (
                                  faqList.map((faq) => (
                                    <div key={faq.id} className="accordion-item opened">
                                      <h2 className="accordion-header">
                                        <button
                                          className="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target={`#flush-collapse${faq.id}`}
                                          aria-expanded="false"
                                          aria-controls={`flush-collapse${faq.id}`}
                                        >
                                          {faq.title}
                                        </button>
                                      </h2>
                                      <div
                                        id={`flush-collapse${faq.id}`}
                                        className="accordion-collapse collapse show"
                                        data-bs-parent="#accordionFlushExample"
                                      >
                                        <div className="accordion-body">
                                          <p>{faq.description}</p>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <div className="text-center">
                                    <p>No FAQ content available.</p>
                                  </div>
                                )}
                              </div>
                            </div>

                      </div>

                    </div>

                   
                    <div className="bs-callout bs-callout-danger">
                      <h6>Copy Hub Walkthrough</h6>
                      <div className="row">

                      <div className="col-lg-6">
                        <div className="howtousevideo">
                          <iframe
                            style={{ margin: "20px 0" }}
                            width="100%"
                            height="315"
                            src={videoContent?.template || ''}
                            title="Template Video Content"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                      <div className="col-lg-6">
                              <div className="d-flex align-items-center mb-2">
                                <div className="font18 font600">FAQ</div>
                              </div>
                              <div className="accordion accordion-flush discover-accordion-section mb-4" id="accordionFlushExample">
                                {faq2List.length > 0 ? (
                                  faq2List.map((faq2) => (
                                    <div key={faq2.id} className="accordion-item opened">
                                      <h2 className="accordion-header">
                                        <button
                                          className="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target={`#flush-collapse${faq2.id}`}
                                          aria-expanded="false"
                                          aria-controls={`flush-collapse${faq2.id}`}
                                        >
                                          {faq2.title}
                                        </button>
                                      </h2>
                                      <div
                                        id={`flush-collapse${faq2.id}`}
                                        className="accordion-collapse collapse show"
                                        data-bs-parent="#accordionFlushExample"
                                      >
                                        <div className="accordion-body">
                                          <p>{faq2.description}</p>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <div className="text-center">
                                    <p>No FAQ content available.</p>
                                  </div>
                                )}
                              </div>
                            </div>

                      </div>
                    
                    </div>
                 
                    {/* <div className="bs-callout bs-callout-danger">
                      <h6>Ask for Help</h6>
                      <div className="col-lg-6">
                        <div className="howtousevideo">
                          <iframe
                            style={{ margin: "20px 0" }}
                            width="100%"
                            height="315"
                            src={videoContent?.help || ''}
                            title="Help Video Content"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                    </div> */}
                   
 
</div>
    </div>
</section>

  );
};

export default Guide;
