// import React, { useState, useEffect } from 'react';

// // Define the interface for the data prop
// interface TypingEffectProps {
//     text: string;
//     speed: number;
// }

// const TypingEffect: React.FC<TypingEffectProps> = ({ text, speed }) => {
//     const [displayedText, setDisplayedText] = useState<string>('');
//     const [index, setIndex] = useState<number>(0);
//     const paragraphs = text.trim().split('\n\n'); // Split by double newlines for paragraphs

//     useEffect(() => {
//         if (index < paragraphs.length) {
//           const paragraph = paragraphs[index];
//           let charIndex = 0;
      
//           const typeCharacter = () => {
//             const chunkSize = 5; // adjust this value to control the speed of typing
//             const chunk = paragraph.substring(charIndex, charIndex + chunkSize);
//             setDisplayedText((prev) => prev + chunk);
//             charIndex += chunkSize;
//             if (charIndex < paragraph.length) {
//               setTimeout(typeCharacter, speed);
//             } else {
//               // Move to the next paragraph after a short delay
//               setIndex((prev) => prev + 1);
//               setDisplayedText((prev) => prev + '\n\n'); // Add a newline between paragraphs
//             }
//           };
      
//           // Start typing the current paragraph
//           typeCharacter();
//         }
//       }, [index, speed]);

//     return (
//         <div className="bg-white border rounded p-3 template-result-output">
//             <pre style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} className='pre'>{displayedText}</pre>
//         </div>
//     );
// };

// export default TypingEffect;



import React, { useState, useEffect } from 'react';

// Define the interface for the data prop
interface TypingEffectProps {
    text: string;
    speed: number;
}

// Helper function to format text for bold and italic
const formatText = (text: string): string => {
    return text
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Bold: **text**
        .replace(/#(.*?)#/g, '<em>$1</em>'); // Italic: #text#
};

const TypingEffect: React.FC<TypingEffectProps> = ({ text, speed }) => {
    const [displayedText, setDisplayedText] = useState<string>('');
    const [index, setIndex] = useState<number>(0);
    const paragraphs = text.trim().split('\n\n'); // Split by double newlines for paragraphs

    useEffect(() => {
        if (index < paragraphs.length) {
            const paragraph = paragraphs[index];
            let charIndex = 0;

            const typeCharacter = () => {
                const chunkSize = 5; // adjust this value to control the speed of typing
                const chunk = paragraph.substring(charIndex, charIndex + chunkSize);
                setDisplayedText((prev) => prev + chunk);
                charIndex += chunkSize;
                if (charIndex < paragraph.length) {
                    setTimeout(typeCharacter, speed);
                } else {
                    // Move to the next paragraph after a short delay
                    setIndex((prev) => prev + 1);
                    setDisplayedText((prev) => prev + '\n\n'); // Add a newline between paragraphs
                }
            };

            // Start typing the current paragraph
            typeCharacter();
        }
    }, [index, speed]);

    return (
        <div className="bg-white border rounded p-3 template-result-output">
            <pre
                style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                className="pre"
                dangerouslySetInnerHTML={{ __html: formatText(displayedText) }} // Render formatted text
            ></pre>
        </div>
    );
};

export default TypingEffect;
