import React, { useEffect, useState, useRef } from 'react';
import { HTMLTable, H2, ButtonGroup, Button } from '@blueprintjs/core';
import { axiosRequestGet } from '../../network/axiosRequest';
import { useAuthUser } from 'react-auth-kit';
import Swal from 'sweetalert2';
import '../../assets/template/css/subscription.css';
import '../../assets/template/css/fonts.css';
import '../../assets/template/css/bootstrap.min.css';
import Header from '../../components/header';
import Loader from '../../components/loader';
import DataTable, { Api } from 'datatables.net-dt'; // Import DataTable
import 'datatables.net-responsive-dt';
import 'datatables.net-dt/css/dataTables.dataTables.css';import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery'; 
 
interface RequestData {
  id: number;
  user_id: number;
  first_name: string;
  last_name: string;  
  subscription_plan_id: number;
  plan_name: string;
  transaction_id: number;
  amount: number;
  created_at_transaction: string;
  cancel_at: string;
  payment_status: number;
  invoice: string;
}

const ListPayment: React.FC = () => {
  const auth = useAuthUser();
  const authData = auth();
  const navigate = useNavigate();
  const userId = authData?.user_id;
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState<any[]>([]);

  const tableRef = useRef<HTMLTableElement>(null); // Create a ref for the table
  const dataTableInstanceRef = useRef<Api | null>(null);

  useEffect(() => {
    fetchPayments();
  }, []);

  useEffect(() => {
    if (payments.length > 0) {
      initializeDataTable(); // Initialize DataTable when payments data is set
    }
  }, [payments]);

  const fetchPayments = async () => {
    try {
      if (!authData || !authData.token) {
        throw new Error('User is not authenticated.');
      }

      const headers = {
        Authorization: `Bearer ${authData.token}`,
        'Content-Type': 'application/json',
      };

      // Fetch payments for the user
      const paymentsResponse = await axiosRequestGet(`/user/payments/${userId}`, headers);
      console.log('Full API Response:', paymentsResponse);  // Log full response

      // Correctly extract payment data from the response
      const paymentData = paymentsResponse?.data || []; // Adjusted to extract the data directly
      console.log('Fetched Payments:', paymentData); // Log extracted payment data

      setPayments(paymentData); // Set the actual payment array
      setLoading(false);
    } catch (error) {
      handleFetchError(error);
    }
  };

  const handleFetchError = (error: unknown) => {
    console.error(error); // Log the error for debugging
    Swal.fire({
      title: 'Error',
      text: 'Failed to load payments. Please try again later.',
      icon: 'error',
      customClass: {
        confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
      },
      confirmButtonText: 'OK',
    });
    setLoading(false);
  };
  const initializeDataTable = () => {
    if (tableRef.current) {
      // Destroy previous instance if it exists
      $(tableRef.current).DataTable().destroy();
  
      const table = $(tableRef.current).DataTable({
        processing: true,
        serverSide: true,
        ajax: (dataTablesParams: any, callback: any) => {
          // Send request to server with pagination, sorting, and filtering parameters
          axiosRequestGet('/admin/payments', {
            headers: {
              Authorization: `Bearer ${auth()!.token}`, // Ensure "Bearer" is used here
              'Content-Type': 'application/json',
            },
            params: {
              draw: dataTablesParams.draw,
              start: dataTablesParams.start,
              length: dataTablesParams.length,
              search: dataTablesParams.search.value, // Pass search value
              order: dataTablesParams.order[0].dir,
              orderColumn: dataTablesParams.columns[dataTablesParams.order[0].column].data,
            },
          })
            .then((response: any) => {
              // Pass the data back to DataTable
              callback({
                draw: response.draw,
                recordsTotal: response.recordsTotal,
                recordsFiltered: response.recordsFiltered,
                data: response.data,
              });
            })
            .catch((error: any) => {
              console.error('Error fetching data:', error);
            });
        },
        columns: [
          { title: 'Name', data: 'first_name' },
          { title: 'Plan', data: 'plan_name' },
          { title: 'Amount', data: 'amount' },
          { title: 'Transaction Id', data: 'transaction_id' },
          { title: 'Created At', data: 'created_at_transaction' },
          { title: 'Cancel At', data: 'cancel_at' },
          { title: 'Action', data: null, orderable: false, searchable: false },
        ],
        createdRow: function (row, data: any) {
          const typedData = data as RequestData;
          $('td:eq(0)', row).html(`${data.first_name} ${data.last_name}`);
  
          const actionsDiv = document.createElement('div');
          $('td:eq(6)', row).html(actionsDiv);
  
          ReactDOM.render(
            <ButtonGroup style={{ width: '50px' }} className="d-flex justify-content-between" minimal={true}>
              <Button
                className="btn btn-icon btn-primary btn-xs"
                onClick={() => navigate(`/view-payments/${typedData.user_id}/${typedData.id}`)}
                intent="primary"
                icon="eye-open"
              ></Button>
            </ButtonGroup>,
            actionsDiv
          );
        },
        responsive: true,
        searching: true, // Enable searching
        paging: true,
      });
    }
  };
  

  const breadcrumbs = [
    { label: 'Dashboard', url: '/dashboard' },
    { label: 'Payments' },
  ];

  return (
    <section className="main-right-wrapper">
      <Header pageTitle="Payment List" breadcrumbs={breadcrumbs} enable={true} />
      <div className="main-right-content">
        <div className="container-fluid p-relative">
          <div className="row wlcom_bx mb-3 d-md-none d-block">
            <div className="col-12">
              <div className="page-title-box">
                <h2 className="page-title">Payments</h2>
                <div className="breadcrumb">
                  {breadcrumbs.map((breadcrumb: any, index: any) => (
                    <span key={index}>
                      {breadcrumb.url ? (
                        <a href={breadcrumb.url}>{breadcrumb.label}</a>
                      ) : (
                        <span>{breadcrumb.label}</span>
                      )}
                      {index < breadcrumbs.length - 1 && <span className="separator"> / </span>}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {loading ? (
            <Loader />
          ) : payments.length > 0 ? (
            <div className="container" style={{ textAlign: 'left', padding: '10px' }}>
              <H2>Payment List</H2>
              <HTMLTable width={'100%'} ref={tableRef}>
               
              </HTMLTable>
            </div>
          ) : (
            <div className="col-12 text-center">
              <p>No payments found.</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ListPayment;
