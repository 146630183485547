import React, { useEffect, useState } from 'react';
import { Card, H2, HTMLTable, Button } from '@blueprintjs/core';
import { axiosRequestGet } from '../../network/axiosRequest';
import { useAuthUser } from 'react-auth-kit';
import Swal from 'sweetalert2';
import '../../assets/template/css/subscription.css';
import '../../assets/template/css/fonts.css';
import '../../assets/template/css/bootstrap.min.css';
import Header from '../../components/header';
import Loader from '../../components/loader';
import { useNavigate, useParams } from 'react-router-dom';

interface PaymentDetails {
    id: number;
    user_id: number;
    first_name: string;
    last_name: string;
    subscription_plan_id: number | null;
    plan_name: string | null;
    transaction_id: string;
    amount: string;
    created_at_transaction: string;
    cancel_at: string | null;
    payment_status: number;
    invoice: string;
  }
  
const ViewPayment: React.FC = () => {
  const auth = useAuthUser();
  const authData = auth();
  const navigate = useNavigate();
  const { userId, paymentId } = useParams<{ userId: string; paymentId: string }>();

  const [loading, setLoading] = useState(true);
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetails | null>(null);

  useEffect(() => {
    if (userId && paymentId) {
      fetchPaymentDetails(parseInt(userId), parseInt(paymentId));
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Invalid payment details. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK',
      }).then(() => navigate('/listpayment'));
    }
  }, [userId, paymentId]);

  const fetchPaymentDetails = async (userId: number, paymentId: number) => {
    try {
      if (!authData || !authData.token) {
        throw new Error('User is not authenticated.');
      }
  
      const headers = {
        Authorization: `Bearer ${authData.token}`,
        'Content-Type': 'application/json',
      };
  
      // Fetch data from the API
      const response = await axiosRequestGet(`/user/payments/${userId}/${paymentId}`, headers);
  
      // Log the full response to confirm it matches the structure
      console.log('Full API Response:', response);
  
      // Directly use response since it contains the payment data
      if (response && response.id) {
        const paymentData: PaymentDetails = response;
        console.log('Payment Data:', paymentData); // Log payment data to verify
        setPaymentDetails(paymentData);
      } else {
        throw new Error('No data found in API response.');
      }
  
      setLoading(false);
    } catch (error) {
      handleFetchError(error);
    }
  };
  
  
  

  const handleFetchError = (error: unknown) => {
    console.error('Fetch Payment Details Error:', error);
    Swal.fire({
      title: 'Error',
      text:
        error instanceof Error
          ? error.message
          : 'Failed to load payment details. Please try again later.',
      icon: 'error',
      confirmButtonText: 'OK',
    });
    setLoading(false);
  };
  

  const breadcrumbs = [
    { label: 'Dashboard', url: '/dashboard' },
    { label: 'Payments', url: '/listpayment' },
    { label: 'View Payment' },
  ];

  return (
    <section className="main-right-wrapper">
      <Header pageTitle="View Payment" breadcrumbs={breadcrumbs} enable={true} />
      <div className="main-right-content">
        <div className="container-fluid">
          {loading ? (
            <Loader />
          ) : paymentDetails ? (
            <Card>
              <H2>Payment Details</H2>
              <div className="bs-callout bs-callout-danger">
                    <br /><br /><br />
                    <div className="row">
                    <div className="col-md-6">
                        <label><b>Name</b></label>
                    </div>
                    <div className="col-md-6">
                        <div>{`${paymentDetails.first_name} ${paymentDetails.last_name}`}</div>
                    </div><br /><br />
                    <div className="col-md-6">
                        <label><b>Plan</b></label>
                    </div>
                    <div className="col-md-6">
                        <div>{paymentDetails.plan_name || 'N/A'}</div>
                    </div><br /><br />
                    <div className="col-md-6">
                        <label><b>Amount</b></label>
                    </div>
                    <div className="col-md-6">
                        <div>{paymentDetails.amount}</div>
                    </div><br /><br />
                    <div className="col-md-6">
                        <label><b>Transaction ID</b></label>
                    </div>
                    <div className="col-md-6">
                        <div>{paymentDetails.transaction_id}</div>
                    </div><br /><br />
                    <div className="col-md-6">
                        <label><b>Subscription / Top up Date</b></label>
                    </div>
                    <div className="col-md-6">
                        <div>{new Date(paymentDetails.created_at_transaction).toLocaleString()}</div>
                    </div><br /><br />
                    <div className="col-md-6">
                        <label><b>Subscription / Top up  Cancel Date</b></label>
                    </div>
                    <div className="col-md-6">
                        <div>{paymentDetails.cancel_at || 'N/A'}</div>
                    </div><br /><br />
                    <div className="col-md-6">
                        <label><b>Payment Status</b></label>
                    </div>
                    <div className="col-md-6">
                        <div>{paymentDetails.payment_status === 0 ? 'Success' : 'Failed'}</div>
                    </div><br /><br />
                    <div className="col-md-6">
                        <label><b>Invoice</b></label>
                    </div>
                    <div className="col-md-6">
                    <div>
                        {paymentDetails.invoice ? (
                        <button
                            onClick={() => window.open(paymentDetails.invoice, "_blank")}
                            className="btn btn-primary primary-filled-button py-2 px-4"
                        >
                            Download Invoice
                        </button>
                        ) : (
                        'N/A'
                        )}
                    </div>
                    </div><br /><br />
                    </div>
                </div>
            </Card>
          ) : (
            <p>No payment details found.</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default ViewPayment;
