import React, { useEffect, useState } from 'react';
import { Button } from '@blueprintjs/core';
import { useAuthUser } from 'react-auth-kit';
import { axiosRequestGet, axiosRequest } from '../../network/axiosRequest';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import '../../assets/template/css/style.css';
import icon from '../../assets/template/images/command.svg';
import Header from '../../components/header';

interface UserAvatarHistory {
  id: number;
  user_id: number;
  user_avatar_id: number;
  avatar_name: string;
  template_id: number;
  title: string;
  user_output: string;
  created_at_history: string;
  updated_at_history?: string;
  liked: number; // Add liked status field
}

const HistoryOutput: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const auth = useAuthUser();
  const token = auth()?.token;
  const [thumbsUp, setThumbsUp] = useState(false);
  const [thumbsDown, setThumbsDown] = useState(false);
  const navigate = useNavigate();
  const [reaction, setReaction] = useState<number | null>(null);
  const [avatarHistory, setAvatarHistory] = useState<UserAvatarHistory[]>([]);
  const [selectedAvatar, setSelectedAvatar] = useState<UserAvatarHistory | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!token) throw new Error('User is not authenticated.');

        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };

        // Fetch user template avatar history
        const response = await axiosRequestGet('/users/template/userAvatarHistoryByUser', headers);
        setAvatarHistory(response);
        
        // Pre-select an avatar if id is in the URL
        if (id) {
          const avatar = response.find((avatar: UserAvatarHistory) => avatar.id === Number(id));
          if (avatar) {
            setSelectedAvatar(avatar);
            setThumbsUp(avatar.liked === 1);
            setThumbsDown(avatar.liked === 2);
          }
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error('Failed to fetch data:', error.message);
          setError('Failed to load data.');
        } else {
          console.error('An unknown error occurred:', error);
          setError('An unknown error occurred.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, token]);

  const handleAvatarSelect = async (avatarId: number) => {
    try {
      if (!token) throw new Error('User is not authenticated.');

      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

      // Update the selected avatar and navigate to the new URL with the avatarId
      const response = await axiosRequestGet(`/admin/userAvatarHistoryById/${avatarId}`, headers);
      setSelectedAvatar(response);
      setThumbsUp(response.liked === 1);
      setThumbsDown(response.liked === 2);
      navigate(`/history-output/${avatarId}`, { replace: true });
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Failed to fetch data:', error.message);
        setError('Failed to load data.');
      } else {
        console.error('An unknown error occurred:', error);
        setError('An unknown error occurred.');
      }
    }
  };

  const handleCopy = () => {
    if (!selectedAvatar || !selectedAvatar.user_output) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No output to copy!',
        customClass: {
          confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
          cancelButton: 'btn btn-danger danger-filled-button py-2 px-4'
        }
      });
      return;
    }

    // Copy the response text to the clipboard
    navigator.clipboard.writeText(selectedAvatar.user_output).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Copied!',
        text: 'The output has been copied to your clipboard.',
        customClass: {
          confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
          cancelButton: 'btn btn-danger danger-filled-button py-2 px-4'
        }
      });
    }).catch((error) => {
      console.error('Failed to copy text:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to copy the output. Please try again.',
        customClass: {
          confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
          cancelButton: 'btn btn-danger danger-filled-button py-2 px-4'
        }
      });
    });
  };

  const reactToHistory = async (reactionType: number) => {
    try {
      if (!token || !selectedAvatar) return;

      await axiosRequest(`/admin/userAvatarHistory/react/${id}`, {
        reaction: reactionType,
      }, {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      });

      setReaction(reactionType);
      setThumbsUp(reactionType === 1);
      setThumbsDown(reactionType === 2);
    } catch (error) {
      console.error(`Error reacting to My Copies: ${error}`);
    }
  };

  const handleThumbsUp = () => {
    if (!selectedAvatar || !selectedAvatar.user_output) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No output to like!',
        customClass: {
          confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
          cancelButton: 'btn btn-danger danger-filled-button py-2 px-4'
        }
      });
      return;
    }
    if (reaction !== 1) {
      reactToHistory(1);
    }
    setThumbsUp((prev) => !prev);
    if (thumbsDown) setThumbsDown(false); // Prevent both being selected
  };

  const handleThumbsDown = () => {
    if (!selectedAvatar || !selectedAvatar.user_output) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No output to dislike!',
        customClass: {
          confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
          cancelButton: 'btn btn-danger danger-filled-button py-2 px-4'
        }
      });
      return;
    }
    if (reaction !== 2) {
      reactToHistory(2);
    }
    setThumbsDown((prev) => !prev);
    if (thumbsUp) setThumbsUp(false); // Prevent both being selected
  };
  

  const breadcrumbs = [
    { label: 'Dashboard', url: '/dashboard' },
    { label: 'My Copies' },
  ];

  return (
    <section className="main-right-wrapper">
      <Header pageTitle="My Copies" breadcrumbs={breadcrumbs} enable={true} />
      <div className="main-right-content">
        <div className="container-fluid">
          <div className="row wlcom_bx mb-3 d-md-none d-block">
            <div className="col-12">
              <div className="page-title-box">
                <h2 className="page-title">My Copies Output</h2>
                <div className="breadcrumb">
                  {breadcrumbs.map((breadcrumb, index) => (
                    <span key={index}>
                      {breadcrumb.url ? (
                        <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                      ) : (
                        <span>{breadcrumb.label}</span>
                      )}
                      {index < breadcrumbs.length - 1 && <span className="separator"> / </span>}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-5 col-xl-4">
              <div className="p-3 bg-light rounded mb-4" style={{maxHeight: '100vh', overflowY: 'auto'}}>
                <h5 className="mb-3 text-center">My Copies</h5>
                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  {avatarHistory.map((avatar) => (
                    <button
                      key={avatar.id}
                      className={`nav-link avatar-card-tab-item ${avatar.id === selectedAvatar?.id ? 'active' : ''}`}
                      id={`v-pills-avatar${avatar.id}-tab`}
                      data-bs-toggle="pill"
                      data-bs-target={`#v-pills-avatar${avatar.id}`}
                      type="button"
                      role="tab"
                      aria-controls={`v-pills-avatar${avatar.id}`}
                      aria-selected={avatar.id === selectedAvatar?.id}
                      onClick={() => handleAvatarSelect(avatar.id)}
                    >
                      <div className="icon-box-circle">
                        <img src={icon} alt="avatar" />
                      </div>
                      <h4 className="font15 mb-1">{avatar.title}</h4>
                      <p className="mb-0 font-light-muted font14">{avatar.avatar_name}</p>
                      <p className="mb-0 font-light-muted font14">{avatar.created_at_history}</p>
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-7 col-xl-8">
              <div className="p-3 bg-light rounded mb-4" style={{maxHeight: '100vh', overflowY: 'auto'}}>
                <div className="d-flex align-items-center mb-2">
                  <h5 className="text-center">Output</h5>
                  <div className="ms-auto font15 d-flex gap-3">
                    <Link to="#" onClick={handleCopy}>
                      <i className="fa fa-clone"></i>
                    </Link>
                    <Link to="#" onClick={handleThumbsUp}>
                    <i className={`fa ${thumbsUp ? "fa-thumbs-up" : "fa-thumbs-o-up"}`}></i>
                    </Link>
                    <Link to="#" onClick={handleThumbsDown}>
                    <i className={`fa ${thumbsDown ? "fa-thumbs-down" : "fa-thumbs-o-down"}`}></i>
                    </Link>
                  </div>
                </div>
                <div className="bg-white border rounded p-3 template-result-output">
                {selectedAvatar ? (
                    <>
                        {selectedAvatar.user_output.split('\n').map((para, index) => (
                            <p key={index}>{para}</p>
                        ))}
                    </>
                ) : (
                    <p>Select an Ideal Client to see the output.</p>
                )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HistoryOutput;
